import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {MatIconModule} from '@angular/material/icon';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-boolean-cell-renderer',
  templateUrl: './boolean-cell-renderer.component.html',
  styleUrls: ['./boolean-cell-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule]
})
export class BooleanCellRendererComponent implements ICellRendererAngularComp {
  isSet: boolean = false;

  agInit(params): void {
    this.isSet = !!params.value;
  }

  refresh(): boolean {
    return false;
  }
}
