import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {PMonitoringStatusSource} from '../../../../../model/enums/source/p-monitoring-status.source';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-status-monitoring-cell-renderer',
  templateUrl: './status-monitoring-cell-renderer.component.html',
  styleUrls: ['./status-monitoring-cell-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, MatTooltipModule]
})
export class StatusMonitoringCellRendererComponent implements ICellRendererAngularComp {
  value: number;
  St: PMonitoringStatusSource;

  agInit(params): void {
    if (params.data.isValidated) {
      if (params.data.idPMonitoringStatus === 2) {
        this.value = 1;
      } else if (params.data.idPMonitoringStatus === 1) {
        this.value = 2;
      } else {
        this.value = 4;
      }
    } else {
      if (params.data.idPMonitoringStatus === 1 || params.data.idPMonitoringStatus === 2) {
        this.value = 3;
      } else {
        this.value = 4;
      }
    }
  }

  refresh(): boolean {
    return false;
  }
}
