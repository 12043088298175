import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private readonly toastrService: ToastrService) {}

  public success(message: string, title: string = 'Succès') {
    return this.toastrService.success(message, title);
  }

  public error(message: string, title: string = 'Erreur') {
    return this.toastrService.error(message, title);
  }

  public info(message: string, title: string = 'Information') {
    return this.toastrService.warning(message, title);
  }

  public warn(message: string, title?: string) {
    return this.toastrService.warning(message, title);
  }
}
