<span *ngIf="value && value === 1" class="badge rounded-pill badge-success">
  <mat-icon [matTooltip]="'Actif'">
    done
  </mat-icon>
</span>
<span *ngIf="value && value === 2" class="badge rounded-pill badge-primary">
  <mat-icon [matTooltip]="'En veille'">
    dark_mode
  </mat-icon>
</span>
<span *ngIf="value && value === 3" class="badge rounded-pill badge-danger">
  <mat-icon [matTooltip]="'En attente autorisations'">
    do_not_disturb_on_total_silence
  </mat-icon>
</span>
<span *ngIf="value && value === 4" class="badge rounded-pill badge-secondary">
  <mat-icon [matTooltip]="'Sorti'">
    logout
  </mat-icon>
</span>
